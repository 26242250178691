/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var lang = $('body').hasClass('lang-fr') ? 'fr' : 'en';

  var termPrev = (lang == 'fr') ? "Précédent" : "Previous";
  var termNext = (lang == 'fr') ? "Suivant" : "Next";

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Add 'js' class on HTML tag
        $('html').addClass('js');

        // Add element so we can change its layout in CSS and have a better way
        // to trigger narrow resolution (or any other where there's no other way)
        $('body').append('<div id="responsiveResTrig"></div>');

        $(window).on('resize', function(){
          UTIL.fire('common', 'setResponsiveClasses');

          // Turn tabs into a dropdown if there is not enough space
          UTIL.fire('common', 'changeTabsToDrop', 'resize');
        });

        // Fires Fancybox
        UTIL.fire('common', '_fancybox');

        // Mobile nav menu
        UTIL.fire('common', 'setNavMenus');

        // Add responsive version class on body
        UTIL.fire('common', 'setResponsiveClasses');

        // Collapsible elements
        UTIL.fire('common', 'collapse');

        // Submit btns - use js version (replace the default input by something else)
        UTIL.fire('common', 'switchSubmitBtns');

        // Turn tabs into a dropdown if there is not enough space
        UTIL.fire('common', 'changeTabsToDrop');

        // Turn content into a slider on mobile
        UTIL.fire('common', 'contentSlider');

        // Collapsed text
        UTIL.fire('common', 'readMore');

        // Add class on fields' parent when they already have content in them
        UTIL.fire('common', 'inputContent');

        // Add focus to input when add-on is clicked
        UTIL.fire('common', 'inputFocus');

        // Call BootStrap datepicker
        UTIL.fire('common', 'datepicker');

        // Call BootStrap timepicker
        UTIL.fire('common', 'timepicker');

        // Header - mobile buttons
        UTIL.fire('common', 'headerMobile');

        // MatchHeight functions
        UTIL.fire('common', '_matchHeight');

        // ContactForm7 DomEvents
        UTIL.fire('common', '_cf7_events');

      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Add class on fields' parent when they already have content in them
        UTIL.fire('common', 'inputContent');
      },
      _fancybox: function() {
        if($('.fancybox').length){
          var closeBtnLabel = "Close";

          if($('.lang-fr')){
            closeBtnLabel = "Fermer";
          }

          $('.fancybox').fancybox({
            // maxWidth: 500,
            // padding: 0,
            tpl: {
              closeBtn: '<a title="'+closeBtnLabel+'" class="fancybox-item fancybox-close" href="javascript:;"></a>',
            }
          });
        }
      },
      collapse: function() {
        $('.collapse-btn').on('click', function(e) {
          e.preventDefault();
          var $this = $(this);
          var $collapse = $this.closest('.collapse-group').find('.collapse');
          $collapse.collapse('toggle');
        });
      },
      setResponsiveClasses: function(){
        // Add or remove classes on window resize
        var availableSizes = ['mobile','tablet','narrow','wide','extrawide'];
        var API = $("#mobilenavMain").data( "mmenu" );

        keepSelectedSizeOnly = function(size){
          for (a = 0; a < availableSizes.length; ++a) {
            if (availableSizes[a] != size){ $('body').removeClass(availableSizes[a]);}
          }
          $('body').addClass(size);
        };

        setSize = function(size){
          // If the site doesn't already have the correct class
          if((!$('body.'+size).length)){

            if(size == 'mobile'){
              keepSelectedSizeOnly('mobile');
            }else{
              // Close the mobile nav
              API.close();

              keepSelectedSizeOnly(size);
            }

            // Header - mobile buttons
            UTIL.fire('common', 'headerMobile');
          }
        };

        // is mobile
        if($('#responsiveResTrig').css('width') == '50px') {
          setSize('mobile');
        }
        // is tablet resolution
        else if($('#responsiveResTrig').css('width') == '75px') {
          setSize('tablet');
        }
        // is wide resolution
        else if($('#responsiveResTrig').css('width') == '200px') {
          setSize('wide');
        }
        // is extra-wide resolution
        else if($('#responsiveResTrig').css('width') == '300px') {
          setSize('extrawide');
        }
        // is narrow resolution
        else {
          setSize('narrow');
        }
      },
      setNavMenus: function(){
        var termHome = (lang == 'fr') ? "Accueil" : "Home";

        // Mobile nav menu
        $('#siteHeader').append('<div id="mobilenavMain" class="mobilenav"></div>');
        $('#mainnav ul').clone().appendTo($("#mobilenavMain"));

        $('.mobilenav').mmenu({
          // configuration
          classNames: {
            list: "menu"
          },
          offCanvas: {
            pageSelector: "#page",
            position: "bottom",
            zposition: "front"
          },
          extensions: [
            "theme-dark",
            "positioning",
            "pagedim-black"
          ]
        });

        var API = $("#mobilenavMain").data( "mmenu" );

        $('#mobilebtns').appendTo('body');

        // If using the single CTA version: Change mobilebtns CTA text and link if sidebar form present on page
        if($('#mobilebtns .singleCTA').length && $('#featForm form.feat').length) {
          $('#mobilebtns .cta a').attr('href', '#featForm');
          $('#mobilebtns .cta a > span').html($('#featForm form.feat > header > h2').html().replace(/<br\s*\/?>/gi,' ')); //remove <br> tags
        }

        $(window).scroll(function(){
          if ($('#siteHeader').visible()) {
            $('#mobilebtns').removeClass('visible');
            $('body').removeClass('mobilebtns-visible');
          }
          else {
            $('#mobilebtns').addClass('visible');
            $('body').addClass('mobilebtns-visible');
          }
        }).scroll();


        // ---- HOME LINK ----
        // Add a "Home" link if it's not already in the main nav
        if(!$('#mainnav ul .home').length) {
          $('#mobilenavMain .mm-panels > .mm-panel:first-child > ul').prepend('<li><a href="/' + lang + '/">' + termHome + '</a></li>');
        }

        // ---- QUICK LINKS ----
        // Do not copy the Contact Us link if it is also present in the main nav
        if($('#mainnav ul .contact').length) {
          $('#siteHeader .quicklinks li:not(.contact)').clone().appendTo('#mobilenavMain .mm-panels > .mm-panel:first-child > ul');
        }
        else {
          $('#siteHeader .quicklinks li').clone().appendTo('#mobilenavMain .mm-panels > .mm-panel:first-child > ul');
        }

        // ---- CLIENT ZONE ----
        $('#mobilenavMain .mm-panels > .mm-panel:first-child > ul').append('<li class="client-zone"></li>');
        $('#siteHeader .sub .btn a').clone().appendTo('#mobilenavMain .mm-panels > .mm-panel:first-child > ul .client-zone');

        // ---- LANGUAGE ----
        $('#siteHeader .lng li').addClass('lngSwitcher');
        var lngSwitcher = $('#siteHeader .lng ul').html();
        $(lngSwitcher).appendTo('#mobilenavMain .mm-panels > .mm-panel:first-child > ul');

        // ---- "Empty links"
        $('#mobilenavMain .mm-panels > .mm-panel > ul > li.nolink').each(function(){
          var $thisItem = $(this);

          $thisItem.find('a:not(.mm-next)').attr('href', $thisItem.find('a.mm-next').attr('href'));
        });


        // ---- OPEN/CLOSE MMENU ----
        API.bind('closing', function () {
          $('#mainnav nav button, #mobilebtns .nav button').removeClass('is-active');
          $('#mobilebtns').removeClass('menu-opened');
        });

        API.bind('opening', function () {
          $('#mainnav nav button, #mobilebtns .nav button').addClass('is-active');
          $('#mobilebtns').addClass('menu-opened');
        });

        $('#mainnav nav button, #mobilebtns .nav button').click(function() {
          // If menu is already opened, we need to close it
          if($("#mobilenavMain").hasClass('mm-opened')) {
            API.close();
          }
          else {
            // Close potential dropdowns in mobile btns
            $('#siteHeader .mobilebtns, #mobilebtns').find('> ul > li > a').siblings('.content').hide();
            $('#siteHeader .mobilebtns, #mobilebtns').find('> ul > li').parent().removeClass('on');

            API.open();
          }

          $(this).blur();
        });


        // ---- OPEN MOBILEBTNS DROPDOWNS ----
        $('#siteHeader .mobilebtns, #mobilebtns').find('> ul > li').has('> .content').find('> a').click(function() {
          var $clickedLink = $(this);
          var $otherItems = $('#siteHeader .mobilebtns, #mobilebtns').find('> ul > li > a').not($clickedLink).parent();

          $otherItems.find('> .content').hide();
          $otherItems.removeClass('on');

          // If menu is opened, we need to close it
          if($("#mobilenavMain").hasClass('mm-opened')) {
            API.close();
          }

          if($clickedLink.siblings('.content')) {
            $clickedLink.siblings('.content').toggle();
            $clickedLink.parent().toggleClass('on');

            $clickedLink.blur();

            return false;
          }
        });

        // Main nav drop downs
        UTIL.fire('common', 'mainnavDropdowns');
      },
      mainnavDropdowns: function(){
        // Main nav drop downs
        mainnavPositionDropdown = function($thisItem, $dropdown){
          var $mainwrap       = $('#siteHeader');       // full width of the menu's wrapper
          var wrapperWidth    = $mainwrap.width() - 20; // we should keep 10px between a dropdown and the edge of the wrapper
          var $parentItem     = $thisItem;              // selected menu
          var parentWidth     = $parentItem.width();    // selected menu width

          $dropdown.removeClass('dropShort');

          var dropdownWidth   = $dropdown.outerWidth();      // sub menu width of the selected menu

          // check available space
          if(($parentItem.offset().left + dropdownWidth) <= ($mainwrap.offset().left + wrapperWidth)){
            $dropdown.addClass('dropLeft');
          }
          else {
            // if the trigger item starts past the wrapper's halfway point, the dropdown needs to be position from the right side
            if($parentItem.offset().left > ($mainwrap.offset().left + (wrapperWidth / 2))){
              $dropdown.addClass('dropRight');
            }
            // if not, we position it from the left
            else{
              $dropdown.addClass('dropLeft');
            }
          }

          // set dropdown width so it's at least as wide as the corresponding menu item label's bg
          if(dropdownWidth < parentWidth) {
            $dropdown.addClass('dropShort');
          }
        };

        // all sub menus
        var $mainnavItemWDrop = $('#mainnav nav > ul > li.menu-item-has-children');

        // // add a span around all 1st level links
        // $('#mainnav nav > ul > li > a').wrapInner('<span></span>');

        // add a span around the text links for all 1st level links
        $('#mainnav nav > ul > li > a > span').wrapInner('<span class="label"></span>');

        // add the arrow on the links
        $mainnavItemWDrop.find('> a > span').append('<span class="arrow"></span>');

        // add a class on the sub menu
        $mainnavItemWDrop.find('> ul').wrap('<div class="drop"></div>');

        // add a class on menu to show that there are sub menus
        if($mainnavItemWDrop.length){
          $mainnavItemWDrop.parents('#mainnav nav > ul').addClass('has-drops');
        }

        if(!$('body.mobile').length){
          $mainnavItemWDrop.hoverIntent(function(){
            var $thisItem = $(this);
            mainnavPositionDropdown($thisItem, $thisItem.find('.drop ul'));
            $thisItem.addClass('on');
            $thisItem.find('ul').slideDown(250); // multi-levels not compatible, to rethink (if needed) - fpb - 2015-01-08
          }, function(){
            var $thisItem = $(this);
            var $thisItemNav = $thisItem.find('.drop');

            if($thisItemNav.is(':visible')){
              $thisItemNav.find('ul').slideUp(100, function(){
                $thisItem.removeClass('on');
              });
            }
          });
        }
      },
      headerMobile: function(){
        // Header - mobile buttons

        var $headerLogo = $('#siteHeader .logo');
        var headerBtnsCount = $('#siteHeader .mobilebtns > ul > li').length;
        var headerBtnsWidth = $('#siteHeader .mobilebtns > ul > li:first-child').width();
        var headerBtnsTotalWidth = (headerBtnsCount * headerBtnsWidth) + (headerBtnsCount - 1); //(nb of items * item width) + spacing

        if($('body.mobile, body.tablet').length) {
          if(headerBtnsCount > 0) {
            $headerLogo.css({
              right: headerBtnsTotalWidth
            });
          }
        }
        else {
          $headerLogo.removeAttr('style');
        }
      },
      switchSubmitBtns: function(){
        // Submit btns - use js version (replace the default input by something else)
        if($('form').length){
          $('form').each(function(){
            var $form = $(this);
            var $btnWrap = $form.find('.form-action');
            var $input = $btnWrap.find('input[type="submit"]');

            if($input.length) {
              var btnText = $input.val();

              $btnWrap.append('<div class="btn"><button type="button" class="form-submit wpcf7-submit"><span>' + btnText + '</span></button></div>');

              var $btn = $btnWrap.find('.btn');

              $input.remove();

              $btn.find('button').click(function(){
                $(this).blur();

                $form.submit();

                return false;
              });
            }
          });
        }
      },
      changeTabsToDrop: function(action) {
        // Turn tabs into a dropdown if there is not enough space

        if($('.tabs-group').length) {
          var $tabGroups = $('.tabs-group');

          // If calling this when the window is resized, we need to put back as UL before we calculate anything
          if(action && action == 'resize') {
            $tabGroups.removeClass('btn-group');
            $tabGroups.find('.dropdown-menu').removeClass('dropdown-menu');
          }

          $tabGroups.each(function(){
            var $group = $(this);
            var $tabs = $group.find('.tabs');
            var $tabItems = $tabs.find('> li');
            var tabsWidth = 0;
            var $btn = $group.find('> button');

            // Don't do this for vertical tabs or other ones that we need more control over
            if(!$group.hasClass('noAutoSwitch')) {
              // Normal tabs
              if(!$group.hasClass('customSwitch')) {
                var availableWidth = $group.width();
              }
              // Tabs using a different width calculation
              else {
                var availableWidth = $group.parents('.customSwitchWrap').width() - $group.siblings().width();
              }

              // Calculate tabs width
              $tabItems.each(function(){
                tabsWidth += $(this).outerWidth(true);
              });

              // If there is not enough space for the UL to be displayed on 1 line, show the dropdown instead
              if(tabsWidth > availableWidth) {
                $group.addClass('btn-group');
                $tabs.addClass('dropdown-menu');

                if(!$group.hasClass('noBtnLabelSwitch')) {
                  // Change button label text to current active tab
                  $btn.find('.label').html($group.find('.active a').html());
                }
              }
              else {
                $group.removeClass('btn-group');
                $tabs.removeClass('dropdown-menu');
              }
            }

            // We need to call some Bootstrap JS so the tab switching still works
            $tabItems.find('a').click(function(e){
              // Check if they're real tabs:
              // If .tab-content exists
              if($(this).parents('.tabs-group').siblings('.tab-content').length) {
                // If the "tab" doesn't have a "link" class
                if(!$(this).parent().hasClass('link')) {
                  $tabItems.not($(this).parent()).removeClass('active');

                  e.preventDefault();

                  $(this).tab('show');

                  if(!$group.hasClass('noBtnLabelSwitch')) {
                    // Change button label text to clicked tab
                    $btn.find('.label').html($(this).html());
                  }
                }
              }

              $(this).blur();
            });
          });

        }

        // Make sure the dropdown menu also works
        $('.dropdown-toggle').dropdown();
      },
      contentSlider: function() {
        // Turn content into a slider on mobile

        var $elem = $('.contentSlider');

        if($elem.length) {
          // Make sure there's more than one item, otherwise there's no point to a slider
          if($elem.find('.item').length > 1) {
            $(window).on('resize', function(){
              $elem.each(function(){
                $thisElem = $(this);

                if($('body.mobile').length){
                  // If mobile and slider is not already set up, set it up
                  if(!$thisElem.hasClass('slick-slider')) {
                    // When slider is initialized
                    $thisElem.on('init', function(event, slick){
                      // Add spans in slider pagination
                      $(this).find('.slick-dots li button').wrapInner('<span />');
                    });

                    $thisElem.slick({
                      mobileFirst: true,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="' + termPrev + '" role="button" style="display: block;"><span>' + termPrev + '</span></button>',
                      nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="' + termNext + '" role="button" style="display: block;"><span>' + termNext + '</span></button>',
                      dots: true,
                      responsive: [
                        {
                          breakpoint: 768,
                          settings: 'unslick'
                        }
                      ]
                    });
                  }
                }
                else {
                  // If not mobile but slider is already set up, we need to remove it
                  if($thisElem.hasClass('slick-slider')) {
                    $thisElem.slick('unslick');
                  }
                }
              });
            }).resize();
          }
        }
      },
      readMore: function(){
        // Collapsed text
        toggleText = function(className){
          var termExpand = (lang == 'fr') ? "Lire la suite" : "Read More";
          var termCollapse = (lang == 'fr') ? "Réduire" : "Collapse Text";

          var symbolExpand = '+';
          var symbolCollapse = '-';

          $(className).each(function(){
            // Add Bootstrap class
            $(this).addClass('collapse');

            if(!$(this).find('+ .readmore').length){
              $(this).after('<p class="readmore"><a href="#"><span>' + symbolExpand + ' </span>'+termExpand+'</a></p>');
            }
          });

          $(className +' + .readmore a').click(function(){
            $trig = $(this);
            $textMore = $(this).parent().prev();

            if($textMore.length){
              $textMore.collapse('toggle');

              // Change trigger text when text is expanded
              $textMore.on('shown.bs.collapse', function(){
                $trig.html('<span>' + symbolCollapse + ' </span>' + termCollapse);
              });
              // Change trigger text when text is collapsed
              $textMore.on('hidden.bs.collapse', function(){
                $trig.html('<span>' + symbolExpand + ' </span>' + termExpand);
              });

              $trig.blur();

              return false;
            }
          });
        };

        // Set all read more texts
        toggleText('.textMore:not(.mobile)');

        // Set all read more texts specific to mobile version
        if($('body.mobile').length) {
          toggleText('.textMore.mobile');
        }
      },
      inputContent: function(oElement) {
        // Add class on fields' parent when they already have content in them

        moveLabel = function(oGroup) {
          if(oGroup.length) {
            var oFormControl = oGroup.find('.form-control');

            // If element is a select or is not empty, and if value isn't the same as the label
            if( oFormControl.val().trim() != '' ) {
              oGroup.addClass('move-label');
            }
            else {
              oGroup.removeClass('move-label');
            }
          }
        };
        
        if(oElement) {
          moveLabel(oElement);
        }
        else {
          var oForms = $('#sidebar, #featForm, #mainContent .wpcf7, .fancybox-wrap').find('form');
        
          // ****** MAKE SURE THE CLASS LIST IS THE SAME AS IN THE SCSS FILE ****** 
          oForms.find('.form-group:not(.radio):not(.checkbox):not(.file):not(.has-label)').each(function(){
            moveLabel($(this));
          });
        }
      },
      inputFocus: function() {
        var oForms = $('form');

        // Add class on textareas' parent
        oForms.find('textarea').closest('.form-group').addClass('has-textarea');

        // Add focus to input when add-on is clicked
        if (oForms.find('.input-group-addon')) {
          $('.input-group-addon').click(function() {
            $(this).parent().find('input, select, textarea').focus();
          });
        }

        // Add class to field parent on focus
        oForms.find('.form-control').focus(function(){
          $(this).closest('.form-group').addClass('has-focus move-label');
        });

        // Remove class on field parent on blur
        oForms.find('.form-control').blur(function(){
          $(this).closest('.form-group').removeClass('has-focus');
          
          // Add class on fields' parent when they already have content in them
          UTIL.fire('common', 'inputContent', $(this).closest('.form-group'));
        });
      },
      datepicker: function() {
        // Datepicker
        if ($('form .date').length) {
          $('form .date input').addClass('datepicker');
        }

        if ($('.datepicker').length) {
          $('.datepicker').datepicker({
            dateFormat: 'dd/mm/yy'
          });
        }
      },
      timepicker: function() {
        // Timepicker
        if ($('form .time').length) {
          $('form .time input').addClass('timepicker');
        }

        if ($('.timepicker').length) {
          $('.timepicker').timepicker();
        }
      },

      _matchHeight: function() {
        // ---- FEAT BTNS ----
        if($('#featBtns').length) {
          $('#featBtns .listWImgs .item .img').matchHeight();
          $('#featBtns .listWImgs .item h3').matchHeight();
          $('#featBtns .listWImgs .item .listWImgsContent').matchHeight();
        }
      },

      // ContactForm7 DomEvents
      // Replace deprecated cf7 function on_sent_ok(), on_submit() or other events
      // https://contactform7.com/dom-events/
      _cf7_events: function() {

        // On form submit, add attribute "disabled"
        $('form').each(function(){
          $(this).on('submit', function(){
            $(this).find('.wpcf7-submit:first').prop('disabled', true);
            $(this).find('.form-action:first').addClass("loading");
          });
        });

        // wpcf7mailsent: Fires when an Ajax form submission has completed successfully, and mail has been sent.
        document.addEventListener( 'wpcf7mailsent', function( event ) {
          formConfirmation(event.detail.contactFormId);
          var currentForm = $('form input[value='+event.detail.contactFormId+']').closest('form');
          currentForm.find('.wpcf7-submit:first').prop('disabled', false);
          currentForm.find('.form-action:first').removeClass("loading");
          delete currentForm;
        }, false );

        // wpcf7submit: Fires when an Ajax form submission has completed successfully, regardless of other incidents.
        document.addEventListener( 'wpcf7submit', function( event ) {
          formScroll(event.detail.contactFormId);
          var currentForm = $('form input[value='+event.detail.contactFormId+']').closest('form');
          currentForm.find('.wpcf7-submit:first').prop('disabled', false);
          currentForm.find('.form-action:first').removeClass("loading");
          delete currentForm;
        }, false );

        document.addEventListener( 'wpcf7invalid', function( event ) {
          var currentForm = $('form input[value='+event.detail.contactFormId+']').closest('form');
          currentForm.find('.wpcf7-submit:first').prop('disabled', false);
          currentForm.find('.form-action:first').removeClass("loading");
          delete currentForm;
        }, false );
      },
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Fire home slider
        UTIL.fire('home', 'homeSlider');
      },

      homeSlider: function() {
        // Prepare wrapper for Prev/Next arrows
        $('#slider').append('<div class="controls controls-arrows"><div class="inner"></div></div>');

        // Prepare wrapper for dots (pagination)
        $('#slider').append('<div class="controls controls-dots"><div class="inner"></div></div>');

        // When slider is initialized
        $('#slider .slider').on('init', function(event, slick){
          // Add spans in slider pagination
          $(this).find('.slick-dots li button').wrapInner('<span />');
          $(this).parents('#slider').addClass('is-initialized');

          // Add 'hover' class when hovering the slider
          $(this).parents('#slider').hover(function(){
            $(this).addClass('hover');
          }, function(){
            $(this).removeClass('hover');
          });
        });

        oElisysSliders.createNewSlick('home_slider', '#slider .slider');
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      },
    },

    // Career listing
    'is_careers': {
      init: function() {
        // JavaScript to be fired on the new details page

        // Display About box
        UTIL.fire('is_careers', 'careerForm');
      },

      finalize: function() {
        // JavaScript to be fired after the init JS
      },

      careerForm: function() {
        $(document).on('facetwp-loaded', function() {
          $('.listing .career-list .item').each(function(){
            var label = $(this).find('h3').text();
            var value = $(this).find('h3').text();

            $('select#job').append($('<option>', {
              value: label,
              text: label
            }));
          });
        });
      },
    },

    // Listing pages
    'is_listing': {
      init: function() {
        // JavaScript to be fired on the listing page

        // if( $('body.is-new').length ) {
        //   // Facet infinite scroll
        //   UTIL.fire('is_listing', 'listingNewInfiniteScroll');
        //   UTIL.fire('is_listing', 'filterInStock');
        // }

        // if( $('body.is-used').length ) {
        //   UTIL.fire('is_listing', 'pagerLoader');
        //   UTIL.fire('is_listing', 'filterClearance');
        //   UTIL.fire('is_listing', 'usedStockSearch');
        // }

        // if( $('body.is-promos').length ) {
        //   UTIL.fire('is_listing', 'pagerLoader');
        //   UTIL.fire('is_listing', 'bindResetFilters');
        // }

        if( $('body.is-blog').length ) {
          UTIL.fire('is_listing', 'pagerLoader');
        }

        // if( $('body.is-careers').length ) {
        //   UTIL.fire('is_listing', 'pagerLoader');
        // }

        // if( $('body.is-product-boutique').length ) {
        //   UTIL.fire('is_listing', 'pagerLoader');
        // }
      },

      // Animation/Transition with facet pagination
      pagerLoader: function() {
        var loaded = false;

        var target;
        // var bIsPromo = false;
        var bIsBlog = false;
        // var bIsCareers = false;

        if ($('body.is-blog').length) {
          bIsBlog = true;
          target = '#mainContent .listing .blog-list';
        }

        // if ($('body.is-new').length) {
        //   bIsNew = true;
        //   target = '#mainContent .listing .product-list';
        // }
        // else if ($('body.is-used').length) {
        //   target = '#mainContent .listing .product-list';
        // }
        // else if ($('body.is-promos').length) {
        //   bIsPromo = true;
        //   target = '#mainContent .listing .promotion-list';
        // }
        // else if ($('body.is-careers').length) {
        //   bIsCareers = true;
        //   target = '#mainContent .listing .career-list';
        // }
        // else if ($('body.is-product-boutique').length) {
        //   target = '#mainContent .listing .boutique-list';
        // }

        $(document).on('facetwp-refresh', function() {
          if ( loaded ) {

            $(target).addClass('loading');

            $('<div class="listing-loading"></div>').insertAfter(target);
          }
        });

        $(document).on('facetwp-loaded', function() {

          // Hide container without any options available
          $.each(FWP.settings.num_choices, function(key, val) {
            var $parent = $('.facetwp-facet-' + key).parent();
            if( $parent.attr('data-keepclass') !== 'hidden' ){
              (0 === val) ? $parent.addClass('hidden') : $parent.removeClass('hidden');
            }
          });

          if ( loaded ) {

            $(target).removeClass('loading');

            if ($('#mainContent .listing .listing-loading').length ) {

              $('#mainContent .listing .listing-loading').fadeOut(100);

            }
            if( bIsPromo ) {
              if( ! $('#mainContent #filter .facetwp-facet-promotion_section_'+lang+' .facetwp-radio.checked').length ) {
                UTIL.fire('is_promos', 'resetFilters');
              }
              else {
                $('#mainContent #filter [data-promotion="reset"]:first').removeClass('active');
              }
            }

            if( target ) {
              $('html, body').animate({
                scrollTop: $(target).offset().top
              }, 500);
            }

          }
          else {
            loaded = true;
          }

        });
      },
    },

    // Career listing
    'is_careers': {
      init: function() {
        // JavaScript to be fired on the new details page

        // Display About box
        UTIL.fire('is_careers', 'careerForm');
      },

      finalize: function() {
        // JavaScript to be fired after the init JS
      },

      careerForm: function() {
        $(document).on('facetwp-loaded', function() {
          $('.listing .career-list .item').each(function(){
            var label = $(this).find('h3').text();
            var value = $(this).find('h3').text();

            $('select[id^="job-"]:first').append($('<option>', {
              value: label,
              text: label
            }));
          });
        });
      },
    },

    // CODE TEMPLATE
    /*'body_class_code_template': {
      init: function() {
        // JavaScript to be fired on the used details pages

        // initialize Used vehicle slickJS galleries
        UTIL.fire('body_class_code_template', 'detailsGallery');

        // Columnizer installation procedure
        //   bower install jquery.columnizer --save
        //   Copy JS files from the master repository "/bower_components/jquery.columnizer/src/"

        // Columnizer on Options : on first page load
        UTIL.fire('body_class_code_template', 'columnizeOptions');

        // Columnizer : Window Resize
        UTIL.fire('body_class_code_template', 'onresizeColumnizeOptions');

      },

      finalize: function() {
        // JavaScript to be fired after the init JS
      },

      // Set slickJS galleries for a single used vehicle
      detailsGallery: function() {

        oElisysSliders.createNewSlick('realisation_photos_big', '#vehicle-photos .gallery .slider');
        oElisysSliders.createNewSlick('realisation_photos_nav', '#vehicle-photos .gallery-nav .slider');

      },


      columnizeOptions: function() {
        if(!$('#vehicle-options ul .column').length && (!$('body.mobile').length && !$('body.tablet').length)) {
          $('#vehicle-options ul').columnize({ columns: 3, buildOnce: true, lastNeverTallest: true });
          $('#vehicle-options ul').addClass('columnized');
        }

        if($('#vehicle-options ul .column').length && ($('body.mobile').length || $('body.tablet').length)) {
          $('#vehicle-options ul').uncolumnize();
          $('#vehicle-options ul').removeClass('columnized');
        }
      },

      onresizeColumnizeOptions: function() {
        $(window).on('resize', function(){
          UTIL.fire('body_class_code_template', 'columnizeOptions');
        });
      }

    },*/
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // CF7 on_sent_ok
  formConfirmation = function(form_id) {
    var currentForm = $('form input[value='+form_id+']').closest('form');

    // Hide form
    $(currentForm).find('.fieldsets').slideUp(500);

    // Show confirmation message
    setTimeout(function() {
      $(currentForm).find('.conf-msg').fadeIn(500);
    }, 500);
  };

  // CF7 submit on_sent_ok or not
  formScroll = function(form_id) {
    // Scroll to header / conf msg
    var currentForm = $('form input[value='+form_id+']').closest('form');

    $('html, body').animate({
      scrollTop: $(currentForm).offset().top -20
    }, 500);
  };

  // Prevent multiple ajax calls from CF7 + W3TC
  // (Will need to be removed if site needs to have reCAPTCHA)
  $.fn.wpcf7OnloadRefill=function(){};

})(jQuery); // Fully reference jQuery after this point.
